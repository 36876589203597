<template>
  <v-dialog v-model="value" width="520" @click:outside="$emit('close')">
    <v-card class="container-modal">
      <v-card-title primary-title>
        {{ empresa?.empresa ?? "" }}
        <v-spacer></v-spacer>
        <v-btn color="primary" text fab x-small
          ><v-icon @click="$emit('close')">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-file-input
            v-if="!loading"
            v-model="file"
            accept=".xlsx"
            prepend-icon="mdi-cloud-upload"
            @change="haveFile"
          ></v-file-input>
          <v-layout v-else justify-center>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined color="primary" class="ml-3" @click="downloadFile">
          <v-icon left>mdi-file-excel</v-icon>
          Descargar Plantilla
          <v-icon right>mdi-download</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ml-3"
          :disabled="haveDocument"
          @click="sendFile"
        >
          Cargar archivo

          <v-icon right>mdi-upload</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: [],
      loading: false,
      haveDocument: true,
    };
  },

  methods: {
    ...mapActions("Integrator", ["CargaMasiva"]),

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onload = () => {
            var fileData = reader.result.toString().split(",");
            let myFile = fileData[1];
            resolve(myFile);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        } catch (e) {
          reject(false);
        }
      });
    },

    haveFile(item) {
      if (item != null) {
        this.haveDocument = false;
      } else {
        this.haveDocument = true;
      }
    },

    async sendFile() {
      this.loading = true;
      let base64 = await this.toBase64(this.file);
      let datos = {
        empresa: `${this.empresa.id}`,
        archivoBase64: base64,
      };
      await this.CargaMasiva(datos)
        .then((response) => {
          this.$store.commit("setSnackbar", {
            active: true,
            color: "success",
            top: true,
            right: true,
            text: response.info,
          });
          this.file = [];
          this.$emit("submit");
        })
        .catch((error) => {
          const message =
            error.result?.data?.lista_mensaje?.[0]?.mensaje ?? error.info;
          this.$store.commit("setSnackbar", {
            active: true,
            color: "error",
            top: true,
            right: true,
            text: message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadFile() {
      const url = "/docs/Cargamasiva.xlsx";
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

#flex {
  margin-left: -90%;
}
</style>
