<template>
  <v-container class="not-extended">
    <v-data-table v-bind="{ ...bind.table, ...tableBind }" v-on="tableOn">
      <template v-slot:top>
        <div class="d-flex align-center py-3 px-6">
          <!-- BUSCADOR -->
          <v-text-field
            v-model="search"
            v-bind="bind.search"
            v-on="searchOn"
          ></v-text-field>

          <v-divider
            vertical
            class="mx-3"
            v-if="!$vuetify.breakpoint.xs"
          ></v-divider>

          <!-- SELECTOR DE EMPRESA -->

          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            item-text="empresa"
            dense
            clearable
            return-object
            label="Empresa"
            hide-details
            @change="setup"
          ></v-autocomplete>

          <!-- MENSAJE DE INFORMACION -->
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                small
                fab
                text
                v-on="on"
                v-bind="attrs"
                :disabled="!nombresDeHomologacionesByEmpresa.length > 0"
              >
                <v-icon>mdi-chat-question</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title primary-title>
                Tipo de homologaciones para la empresa:
              </v-card-title>

              <v-card-text>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in nombresDeHomologacionesByEmpresa"
                    :key="index"
                    :title="item.nombre"
                    >{{ item.nombre }}</v-list-item
                  >
                </v-list>
              </v-card-text>
            </v-card>
          </v-tooltip>

          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <!-- BOTON PARA NUEVA HOMOLOGACION -->
          <v-btn
            outlined
            color="primary"
            class="mr-2"
            :disabled="!empresa"
            @click="dialogBulk = true"
            >Carga Masiva</v-btn
          >
          <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" text fab x-small @click="openForm(item)">
          <v-icon> {{ icons.edit }} </v-icon>
        </v-btn>
        <v-btn color="error" v-bind="bind.actions" v-on="on.delete(item)">
          <v-icon> {{ icons.delete }} </v-icon>
        </v-btn>
      </template>

      <template #item.activate="{ item }">
        <v-switch
          :input-value="item?.[activateKey] ?? 'activo'"
          :true-value="true"
          :false-value="false"
          dense
          inset
          color="primary"
          :hint="item?.[activateKey] ? 'Activo' : 'Inactivo'"
          persistent-hint
          :loading="item.loading"
          @change="$emit('activate', { e: $event, item })"
        ></v-switch>
      </template>
    </v-data-table>

    <Modal
      v-model="dialog"
      v-bind="bind.form"
      v-on="on.form"
      :empresas="empresas"
    />

    <ModalBulkLoad
      v-model="dialogBulk"
      @close="dialogBulk = false"
      @submit="setup(), (dialogBulk = false)"
      :empresa="empresa"
    />

    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import Modal from "@/components/Modal/ModalIntegrator/Modal-Homologacion";
import ModalBulkLoad from "@/components/Modal/ModalIntegrator/Modal-bulk-load";
import { mapActions, mapGetters } from "vuex";

import PaginationMixin from "@/Mixins/PaginationMixin.js";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "HomologacionesPage",
  mixins: [PageMixin, PaginationMixin],
  components: {
    Modal,
    ModalBulkLoad,
  },
  data: () => ({
    dialogBulk: false,
    headers: [
      { text: "Id", value: "id", align: "left" },
      { text: "Empresa", value: "empresa.nombre", align: "center" },
      { text: "Homologación", value: "nombre", align: "center" },
      { text: "Código interno", value: "codigo_interno", align: "center" },
      { text: "Código servicio", value: "codigo_servicio", align: "center" },
      { text: "Descripción", value: "descripcion", align: "center" },
      { text: "", value: "actions", align: "center", sortable: false },
    ],
    empresa: null,
    empresas: [],
    itemId: "id",
    nombresDeHomologacionesByEmpresa: [],
  }),

  computed: {
    ...mapGetters("Integrator", ["homologacionesItems"]),

    items() {
      return this.homologacionesItems;
    },
  },

  methods: {
    ...mapActions("Integrator", [
      "getHomologaciones",
      "getCompanies",
      "CrearHomologacion",
      "UpdatHomolog",
      "deleteHomologacion",
    ]),

    setup() {
      this.get_Empresa();
      this.nombresDeHomologacionesByEmpresa = [];
      this.loading = true;
      this.getHomologaciones({
        ...this.pagination,
        id_empresa: this.empresa?.id,
        search: this.search,
      })
        .then((resp) => {
          this.setPaginationFromResponse(resp.pagination);
          this.nombresDeHomologacionesByEmpresa = resp.tipoDeHomologaciones;
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setPaginationFromResponse(pagination = null) {
      if (pagination) {
        this.pagination.page = parseInt(pagination?.page) ?? 1;
        this.pagination.pages = pagination?.paginas ?? 1;
        this.pagination.objects = pagination?.total ?? 0;
      }
    },

    async get_Empresa() {
      await this.getCompanies().then((resp) => {
        this.empresas = resp;
      });
    },

    saveItem(formData) {
      let METHOD = this.CrearHomologacion;
      this.loadingForm = true;
      const payload = {
        nombre: formData.homologacion,
        codigo_servicio: formData.codServ,
        codigo_interno: formData.codInt,
        descripcion: formData.descripcion,
        id_empresa: formData.empresa,
      };
      if (this.selected) {
        METHOD = this.UpdatHomolog;
        payload.id = this.selected.id;
      }
      METHOD(payload)
        .then((response) => {
          this.setup();
          this.closeForm();
          this.snackbar(response.info, "success");
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    deleteMethod() {
      return this.deleteHomologacion;
    },
  },
};
</script>
